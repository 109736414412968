const VENDORS = 'vendors';
const VOLUNTEERS = 'volunteers';
const RECORDS = 'records';
const ADYEN_EVENTS = 'adyen_events';

const collections = {
  VENDORS,
  VOLUNTEERS,
  RECORDS,
  ADYEN_EVENTS,
};
export default collections;
